// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-sections-blog-blog-details-blog-details-js": () => import("./../../../src/sections/Blog/BlogDetails/BlogDetails.js" /* webpackChunkName: "component---src-sections-blog-blog-details-blog-details-js" */),
  "component---src-sections-utility-faq-faq-item-js": () => import("./../../../src/sections/utility/Faq/FaqItem.js" /* webpackChunkName: "component---src-sections-utility-faq-faq-item-js" */)
}

