import React from "react"
import styled from "styled-components/macro"
import { Box } from "~styled"

const HeaderButtonWrapper = styled(Box)`
  .btn {
    min-width: auto;
    height: 42px;
    border-radius: 3px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
    letter-spacing: -0.4px;
    margin-left: 10px;
    color: #fff;
    background-color: #64ac00;
    border-color: #64ac00;
    box-shadow: -5px 5px 30px rgb(100 172 0 / 30%);
    font-size: 15px;
    font-weight: 500;
    height: 50px;
    min-width: 137px;
    border-radius: 500px;
  }
  ul {
    padding: 0;
    margin: 0;
    position: relative;

    @media (min-width: 992px) {
      padding-right: 12px;
      margin-right: 20px;
      &:before {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 2px;
        height: 26px;
        border-right: 2px solid rgba(130, 130, 136, 0.3);
      }
    }

    @media (min-width: 1600px) {
      padding-right: 0px;
    }
    li {
      display: inline-flex;
      align-items: center;
      a {
        display: inline-flex;
        padding: 0 10px;
        color: #262729;
        transition: 0.4s;
        @media (min-width: 1600px) {
          padding: 0 20px;
        }
        &:hover {
          color: #262729;
        }
      }
    }
  }
`
const HeaderButton = ({ btnLink, btnText, as, ...rest }) => {
  return (
    <HeaderButtonWrapper {...rest}>
      <ul className="d-none d-xl-flex list-unstyled">
        <li>
          <a
            href="https://fb.me/monsaiapp"
            target={"_blank"}
            aria-label="Facebook"
          >
            <i className="fab fa-facebook-square" />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/monsaiapp/"
            target={"_blank"}
            aria-label="Instagram"
          >
            <i className="fab fa-instagram" />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/monsaiapp"
            target={"_blank"}
            aria-label="Twitter"
          >
            <i className="fab fa-twitter" />
          </a>
        </li>
      </ul>
      <Box
        as={as}
        target="_blank"
        className="btn btn-2"
        to={btnLink ? btnLink : "/"}
      >
        {btnText}
      </Box>
    </HeaderButtonWrapper>
  )
}

export default HeaderButton
