import React from "react"
import { Helmet } from "react-helmet"
import Header from "../Header"
import socialBanner from "../../../assets/image/og-image.jpg"
import twitterBanner from "../../../assets/image/og-twitter.jpg"
export default function Layout({ children }) {
  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta name="author" content="Monsai" />
        <title>Monsai - Your Personal Growth Companion</title>
        <meta
          name="description"
          content="Monsai helps you unlock your full potential with personalized daily lessons, habit tracking, and progress insights. Start your journey to personal growth today"
        />

        <meta
          property="og:title"
          content="Monsai - Your Personal Growth Companion"
        />
        <meta
          property="og:description"
          content="Turn aspirations into achievements with Monsai. Embark on a journey of self-discovery and personal growth."
        />
        <meta property="og:site_name" content="Monsai" />
        <meta property="og:site" content="https://monsaiapp.com" />
        <meta property="og:url" content="https://monsaiapp.com" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={socialBanner} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@monsaiapp" />
        <meta
          name="twitter:title"
          content="Monsai - Your Personal Growth & Productivity App"
        />
        <meta
          name="twitter:description"
          content="Turn aspirations into achievements with Monsai. Embark on a journey of self-discovery and personal growth."
        />
        <meta name="twitter:image" content={twitterBanner} />

        <meta name="apple-itunes-app" content="app-id=6475244334" />

        {/* <!-- Google tag (gtag.js) --> */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-TH7QYP8CE5"
        />
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-TH7QYP8CE5');
        `}
        </script>
      </Helmet>
      <Header />
      {children}
    </>
  )
}
